import React, { useState } from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';
import NavigationItem from '../NavigationItem';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { quickFilterItems } from './constants';
import screenVariables from '@/ui/shared/styles/screen/screen.scss';
import styles from './quickFilters.scss';
import { device } from '@/Framework/browser/device';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import useRouter from '@/ui/shared/hooks/useNextRouter';

const QuickFilters = () => {
  const [isOpen, setIsOpen] = useState<boolean>(!device().isPhoneSized(parseInt(screenVariables.screenS)));

  const { dataroom } = useDataroomContext();
  const { canUserAccessPrimary, canUserAccessStaging } = useCurrentUserContext();
  const { asPath: pathname } = useRouter();

  const toggleFilters = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={ styles.quickFiltersWrp }
      data-test="sidebarQuickFilters"
    >
      <div
        onClick={ toggleFilters }
        className={ styles.quickFiltersHeader }
      >
        <Icon
          type={ isOpen ? IconType.collapse : IconType.expand }
          className={ styles.toggleIcon }
          data-test="quickFiltersHeadButton"
        />
        <span className={ styles.titleItem }>Quick Filters</span>
      </div>
      { isOpen ? (
        <div className={ styles.quickFiltersChildren }>
          { quickFilterItems(dataroom.name, pathname, canUserAccessPrimary, canUserAccessStaging).map((item) => (
            <NavigationItem
              key={ item.key }
              { ...item }
              className={ styles.quickFiltersItem }
              activeClassName={ styles.quickFiltersActiveItem }
              iconClassName={ styles.quickFiltersItemIcon }
              textClassName={ styles.quickFiltersItemText }
            />
          )) }
        </div>
      ) : null }
    </div>
  );
};

export default QuickFilters;
