import { RedactionEntityType } from '@/dataroom/domain/vo/redaction/RedactionEntityType';
import { RedactionStructureType } from '@/dataroom/domain/vo/redaction/RedactionStructureType';
import { RedactionFrame } from '@/dataroom/domain/vo/redaction/IRedactionFrame';

export enum RedactionAction {
  Add = 'add',
  Delete = 'delete',
}

interface IRedactionAddPayload {
  type: RedactionEntityType,
  label: string,
  entries: {
    entryNumber: number,
    frames: RedactionFrame[],
  }[],
}

interface IRedactionDeletePayload {
  redactionId: string,
}

export interface IRedactionAction {
  type: RedactionStructureType,
  action: RedactionAction,
  data: (IRedactionAddPayload | IRedactionDeletePayload)[],
}
