import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IRedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import { IRedactionEntity } from '@/dataroom/domain/vo/redaction/IRedactionEntity';

@Dependencies(JsonRpcDispatcherFactory)
class RedactionRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
  }

  getRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ collection: IRedactionEntity[] }> => {
    const request = new Request('dataroom.filesystem.file.redaction.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  editRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
      actions: IRedactionAction[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.filesystem.file.redaction.apply', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  removeAllRedaction = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.filesystem.file.redaction.delete_redacted', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default RedactionRepository;
