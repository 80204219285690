import { useState } from 'react';
import dynamic from 'next/dynamic';

import getSupportChatId from '@/ui/shared/helpers/getSupportChatId';
import Logger from '@/Framework/browser/log/Logger';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';
import { useDIContext } from '@/Framework/DI/DIContext';

const DynamicScript = dynamic(() => import('@/ui/shared/components/Landing/ContactSupport/DynamicScript'), {
  ssr: false,
});

interface IProps {
  children: React.ReactNode,
}

const ContactSupport = (props: IProps) => {
  const { container } = useDIContext();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [scriptLoadError, setScriptLoadError] = useState<string | Event>(null);

  const onClick = () => {
    try {
      // @ts-ignore
      window.FrontChat('show');
    } catch (error) {
      Logger.error('Failed to open support chat', {
        error,
        isScriptLoaded,
        scriptLoadError,
      });
    }
  };

  const handleUnreadChange = (e: { unread_count: number }) => {
    e.unread_count && onClick();
  };

  const onInitCompleted = () => {
    // @ts-ignore
    window.FrontChat('onUnreadChange', handleUnreadChange);
  };

  const handleScriptLoad = () => {
    setIsScriptLoaded(true);
    setScriptLoadError(null);

    try {
      const cookieRepository = container.get(CookieRepository);
      // removing cookie as requested from business @amorgunov
      cookieRepository.removeFromCurrentDomain('fcpmuc');
      const chatId = getSupportChatId();
      // @ts-ignore
      window.FrontChat('init', {
        chatId,
        useDefaultLauncher: false,
        onInitCompleted,
      });
    } catch (e) {
      Logger.error(e);
    }
  };

  return (
    <>
      <DynamicScript
        src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
        onLoad={ handleScriptLoad }
        onError={ setScriptLoadError }
      />
      <div
        onClick={ onClick }
        data-test="contactSupportButton"
      >
        { props.children }
      </div>
    </>
  );
};

export default ContactSupport;
