import { RefObject } from 'react';
import { IDrawingTool } from './DrawingTool/IDrawingTool';
import { ITextSelectionTool } from './TextSelectionTool/ITextSelectionTool';

export interface IPoint {
  x: number,
  y: number,
  x1: number,
  y1: number,
}

export enum RedactionToolType {
  Rectangle = 'Rectangle',
  TextSelect = 'TextSelect',
  Search = 'Search',
}

export enum RedactionType {
  Drawing,
  TextSelection,
  Search,
}

export type IShape = IDrawingTool | ITextSelectionTool;

export interface IPropsRedactionTool {
  isEnabled: boolean,
  canvasRef: RefObject<HTMLCanvasElement>,
  scale: number,
  handleInsertShapes: (shapes: IShape[]) => void,
  handleUpdateShape: (id: string, shape: IShape) => void,
}

export interface IRedaction {
  id: string,
  type: RedactionToolType,
  title: string,
  pageIndex: number,
  controlPoints: IPoint[],
}

export interface IRedactionListItem {
  title: string,
  count?: number,
  id: string,
  type: RedactionToolType,
  pageIndex?: number,
  controlPoints?: any[],
  meta: any,
  redactionState: DrawingState,
}

export type ISerializedShape = ReturnType<IShape['serialize']>;

export enum DrawingState {
  Draft,
  Final,
}
