import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import {
  DrawingState,
  ISerializedShape,
  RedactionToolType,
} from '@/ui/shared/modules/DocumentViewer/plugins/RedactionPlugin/types';
import { RedactionEntityType } from '@/dataroom/domain/vo/redaction/RedactionEntityType';
import { RedactionStructureType } from '@/dataroom/domain/vo/redaction/RedactionStructureType';
import { IRedactionAction, RedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

const convertShapesToActions = (shapes: ISerializedShape[], oldRedactionsIds: string[]): IRedactionAction[] => {
  const finalShapesList = shapes.filter((shape) => shape.drawingState === DrawingState.Final);
  const draftShapesList = shapes.filter((shape) => shape.drawingState === DrawingState.Draft);

  const redactionsToDelete = oldRedactionsIds
    .filter((id) => !finalShapesList.some((shape) => shape.id === id))
    .map((id) => ({ redactionId: id }));

  const redactionsToAdd = draftShapesList
    .map((shape, idx) => {
      return ({
        type: RedactionStructureType.Redaction,
        action: RedactionAction.Add,
        data: [{
          type: shape.type === RedactionToolType.Rectangle ? RedactionEntityType.Area : RedactionEntityType.Text,
          label: shape.meta?.selectedText || '',
          entries: [
            {
              entryNumber: idx,
              frames: shape.controlPoints.map((point) => ({
                topLeftX: Math.min(point.x, point.x1),
                topLeftY: Math.min(point.y, point.y1),
                bottomRightX: Math.max(point.x, point.x1),
                bottomRightY: Math.max(point.y, point.y1),
                pageIndex: shape.pageIndex,
              })),
            },
          ],
    }] });
});

  return [
     ...(redactionsToDelete.length
       ? [{ type: RedactionStructureType.Redaction, action: RedactionAction.Delete, data: redactionsToDelete }]
       : []
     ),
    ...redactionsToAdd,
  ];
};

export default function useEdit() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const editRedactions = async (fileId: number, shapes: ISerializedShape[], oldRedactionsIds: string[]) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
        actions: convertShapesToActions(shapes, oldRedactionsIds),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.editRedactions(payload);

      NotificationManager.success(getMessage(messageCodes.DATAROOM_REDACT_FILE_SUCCESS));
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    editRedactions,
  };
}
