import React, { useCallback, useState } from 'react';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import validate from './validator';
import ChangePermissionsSection from '../ChangePermissionsSection';
import FormSection from '../FormSection';
import InlineFieldWrp from '@/dataroom/ui/common/InlineFieldWrp';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import Input from '@/ui/shared/components/Form/Input';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';
import styles from './editLinkForm.scss';

interface IProps {
  initialValues: {},
  isFetching: boolean,
  closeModal: () => void,
  onSubmit: (
    formData: {
      linkId: number,
      name: string,
      href: string,
    },
    permissionGroups: Partial<{
      [key in PermissionGroup]: number[];
    }>,
    shouldUpdate: boolean,
  ) => Promise<{ href: string } | null>,
  name: string,
  userHasSystemManageAccess: boolean,
  isPermissionsSectionVisible: boolean,
  isUserAdminLite: boolean,
  onShowPermissionsSection: () => void,
  item: IFilesystemListItem,
  footerRefCallback: () => void,
}

const EditLinkForm = (
  {
    initialValues,
    isFetching,
    closeModal,
    onSubmit,
    userHasSystemManageAccess,
    isPermissionsSectionVisible,
    isUserAdminLite,
    onShowPermissionsSection,
    item,
    name,
    footerRefCallback,
  }: IProps,
) => {
  const { allEditPermissions: { updatePermissions } } = useFilesystemContext();
  const { tenant } = useDataroomTenantContext();
  const [permissions, setPermissions] = useState<IChangePermissionsType>({});

  const { allItems, selectedItems, disabledIds, defaultCheckboxGroupName, ...permissionGroups } = permissions;
  let shouldUpdate = false;
  const handleFormSubmit = (formData) => onSubmit(formData, permissionGroups, shouldUpdate).then((error) => {
    if (error) return error;
    closeModal();
    updatePermissions();
    return null;
  });

  const { name: appName, hostname } = DataroomTenantConfig.fromCode(tenant);

  const renderForm: TFinalFormRenderProp = useCallback(({
    invalid,
    pristine,
    dirtyFields,
    modifiedSinceLastSubmit,
    hasSubmitErrors,
    hasValidationErrors,
  }, {
    Field,
  }) => {
    shouldUpdate = !!Object.keys(dirtyFields).length;
    return (
      <>
        <FormSection
          pristine={ pristine }
          hasSubmitErrors={ hasSubmitErrors }
          hasValidationErrors={ hasValidationErrors }
          modifiedSinceLastSubmit={ modifiedSinceLastSubmit }
          isFetching={ isFetching }
          closeModal={ closeModal }
          isPermissionsSectionVisible={ isPermissionsSectionVisible }
          isUserAdminLite={ isUserAdminLite }
          userHasSystemManageAccess={ userHasSystemManageAccess }
          onShowPermissionsSection={ onShowPermissionsSection }
          name={ name }
          className={ styles.formSection }
          footerRefCallback={ footerRefCallback }
        >
          <InlineFieldWrp
            name="name"
            label="Display text"
            placeholder={ `e.g. ${ appName }` }
            className={ styles.inlineFieldWrp }
            formFieldClassName={ styles.formField }
            formLabelClassName={ styles.formLabel }
          >
            { (props) => (
              <Field
                { ...props }
                component={ Input }
                dataTest="newLinkNameInput"
                autoFocus
              />
            ) }
          </InlineFieldWrp>
          <InlineFieldWrp
            name="href"
            label="URL"
            placeholder={ `e.g. www.${ hostname }` }
            className={ styles.inlineFieldWrp }
            formFieldClassName={ styles.formField }
            formLabelClassName={ styles.formLabel }
          >
            { (props) => (
              <Field
                { ...props }
                dataTest="newLinkHrefInput"
              >
                { ({ meta, ...props }) => (
                  /* @ts-ignore */
                  <Input
                    { ...props }
                    meta={ {
                      ...meta,
                      error: meta.error || (!modifiedSinceLastSubmit && meta.submitError),
                      submitError: (!modifiedSinceLastSubmit && meta.submitError),
                    } }
                  />
                ) }
              </Field>
            ) }
          </InlineFieldWrp>
        </FormSection>
        { userHasSystemManageAccess && isPermissionsSectionVisible && (
          <ChangePermissionsSection
            invalid={ invalid }
            pristine={ pristine }
            closeModal={ closeModal }
            parentFolderId={ item.id }
            onChange={ setPermissions }
            isFetching={ isFetching }
            permissionGroups={ permissions }
            name={ name }
            footerRefCallback={ footerRefCallback }
          />
        ) }
      </>
    );
  }, [
    isFetching,
    isPermissionsSectionVisible,
    userHasSystemManageAccess,
    permissionGroups,
  ]);

  return (
    <FinalForm
      name="editLinkForm"
      dataTest="editLinkForm"
      initialValues={ initialValues }
      onSubmit={ handleFormSubmit }
      render={ renderForm }
      validate={ validate }
    />
  );
};

export default EditLinkForm;
