import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { RedactionEntityType } from '@/dataroom/domain/vo/redaction/RedactionEntityType';
import { IRedactionEntity } from '@/dataroom/domain/vo/redaction/IRedactionEntity';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { RedactionToolType, IRedaction } from '@/ui/shared/modules/DocumentViewer/plugins/RedactionPlugin/types';

const formatRedactions = (redactions: IRedactionEntity[]): IRedaction[] => {
  return redactions.map((redaction) => {
    return {
      id: redaction.redactionId,
      type: redaction.redactionType === RedactionEntityType.Area
        ? RedactionToolType.Rectangle
        : RedactionToolType.TextSelect,
      title: redaction.redactionLabel,
      // TODO: review case with text selection into two pages
      pageIndex: redaction.frames[0]?.pageIndex,
      controlPoints: redaction.frames.reduce((acc, value) => {
        acc.push({
          x: value.topLeftX,
          y: value.topLeftY,
          x1: value.bottomRightX,
          y1: value.bottomRightY,
          pageIndex: value.pageIndex,
        });

        return acc;
      }, []),
    };
  });
};

export default function useListing() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [redactions, setRedactions] = useState<IRedaction[]>([]);

  const getRedactions = async (fileId: number) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      const response = await redactionRepository.getRedactions(payload);
      setRedactions(formatRedactions(response.collection));
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const resetRedactions = () => {
    setRedactions([]);
  };

  return {
    isFetching,
    redactions,
    getRedactions,
    resetRedactions,
  };
}
